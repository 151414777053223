/* eslint-disable max-len */
/* eslint-disable indent */
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, Modal, Progress, Select, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import dayjs from "dayjs";
import _ from "lodash";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../components/common/Header";
import {
  getProductReviewLastUpdatedDate,
  getProductReviews,
  getProductReviewsNext,
  setCurrentFilterStatus,
  updateProductReviewStatus,
} from "../../redux/actions/productReview";
import { safelistGetProductCategories } from "../../redux/actions/safeList";
import "./ReviewProduct.scss";

export default function ProductsReviewList() {
  const tableContainerRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [productId, setProductId] = useState("");

  const categoriesFlattened = useSelector((state) => state.safeList.categoriesFlattened);
  const productReviews = useSelector((state) => state.productReviews.productReviews);
  const productReviewsNext = useSelector((state) => state.productReviews.productReviewsNext);
  const productReviewLastUpdatedDate = useSelector((state) => state.productReviews.productReviewLastUpdatedDate);
  const currentViewChoice = useSelector((state) => state.productReviews.currentFilterStatus);
  const fetchingProductReviews = useSelector((state) => state.productReviews.fetchingProductReviews);
  const fetchingProductReviewsNext = useSelector((state) => state.productReviews.fetchingProductReviewsNext);

  const [openRangePicker, setOpen] = useState(false);
  const [sorting, setSorting] = useState([
    {
      id: "updated_at",
      desc: true,
    },
  ]);
  const [range, setRange] = useState({
    startDate: dayjs(productReviewLastUpdatedDate?.from ?? undefined).toDate(),
    endDate: dayjs(productReviewLastUpdatedDate?.to ?? undefined).toDate(),
    key: "selection",
  });

  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    const sortMapping = {
      updated_at: "updated_at",
      brand: "brand__name",
      name: "name",
      category: "category__name",
    };

    const defaultSort = "updated_at";

    if (sorting && sorting.length > 0) {
      const { id, desc } = sorting[0];
      const sortKey = sortMapping[id] || defaultSort;
      const sort = desc ? `+${sortKey}` : sortKey;

      dispatch(getProductReviews({ currentViewChoice, sort }));
    } else {
      dispatch(getProductReviews({ currentViewChoice, sort: `+${defaultSort}` }));
    }
  }, [sorting, currentViewChoice]);

  useEffect(() => {
    dispatch(safelistGetProductCategories());
  }, []);

  useEffect(() => {
    dispatch(getProductReviewLastUpdatedDate(currentViewChoice));
  }, [currentViewChoice]);

  const refOne = useRef(null);

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const handleProductReviewActions = (id, status) => {
    dispatch(updateProductReviewStatus({ id, status }));
  };

  useEffect(() => {
    setRange({
      startDate: dayjs(productReviewLastUpdatedDate.from_date || productReviewLastUpdatedDate.from).toDate(),
      endDate: dayjs(productReviewLastUpdatedDate.to_date || productReviewLastUpdatedDate.to).toDate(),
      key: "selection",
    });
  }, [productReviewLastUpdatedDate]);

  const handleFilterByDate = () => {
    dispatch(
      getProductReviews({
        startDate: dayjs(range.startDate).startOf("day").toISOString(),
        endDate: dayjs(range.endDate).endOf("day").toISOString(),
        currentViewChoice,
      }),
    );

    setOpen(false);
  };

  function ReviewAction({ resultId, brand, ingredients, name, category }) {
    const isProductIncomplete = _.isEmpty(brand) || !(ingredients?.length > 0) || _.isEmpty(name) || _.isNull(category);

    return (
      <div className="d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
        {currentViewChoice !== 0 && (
          <div className="mx-2">
            <button
              className="font-button"
              type="button"
              onClick={() => {
                if (isProductIncomplete) {
                  setProductId(resultId);
                  open();
                } else {
                  handleProductReviewActions(resultId, 0);
                }
              }}
            >
              <FontAwesomeIcon icon={faCheck} color="#13aa48" size="xl" />
            </button>
          </div>
        )}
        {currentViewChoice !== 2 && (
          <div className="mx-2">
            <button
              type="button"
              className="font-button"
              onClick={() => {
                handleProductReviewActions(resultId, 2);
              }}
            >
              <FontAwesomeIcon icon={faXmark} color="#f50000" size="xl" />
            </button>
          </div>
        )}
      </div>
    );
  }

  const loadNextValue = () => {
    if (productReviewsNext.length > 0) {
      dispatch(getProductReviewsNext());
    }
  };

  const filterString = `Filtered for : ${dayjs(range.startDate ?? undefined).format("MMM D, YY")} to ${dayjs(
    range.endDate ?? undefined
  ).format("MMM D, YY")}`;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 350 &&
          productReviewsNext?.length > 0 &&
          !fetchingProductReviews &&
          !fetchingProductReviewsNext
        ) {
          loadNextValue();
        }
      }
    },
    [loadNextValue],
  );

  const handleFilterChange = (value) => {
    dispatch(setCurrentFilterStatus(value));
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const columns = [
    {
      header: "Brand",
      accessorKey: "brand",
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Product Category",
      accessorKey: "category",
      Cell: ({ cell }) => {
        const findValue = _.find(categoriesFlattened, ["id", cell.getValue()]);

        return _.get(findValue, "name", "");
      },
    },
    {
      header: "Last Updated",
      accessorKey: "updated_at",
      Cell: ({ cell }) => dayjs(cell.getValue()).format("MMM DD, YYYY h:m A"),
    },
    {
      header: "Submitting User",
      accessorKey: "submitting_user",
      enableColumnActions: false,
      enableSorting: false,
      size: 100,
    },
    {
      header: "Action",
      accessorKey: "id",
      Cell: ({ cell, row }) => {
        return (
          <ReviewAction
            resultId={cell.getValue()}
            ingredients={row.original.ingredient}
            brand={row.getValue("brand")}
            name={row.getValue("name")}
            category={row.original.category}
          />
        );
      },
      mantineTableHeadCellProps: {
        align: "center",
      },
      enableColumnActions: false,
      enableSorting: false,
    },
  ];

  const table = useMantineReactTable({
    columns,
    data: productReviews,
    mantineTableContainerProps: {
      ref: tableContainerRef,
      sx: { maxHeight: "530px", minHeight: "530px" },
      onScroll: (event) => fetchMoreOnBottomReached(event.target),
    },
    enablePagination: false,
    enableRowNumbers: true,
    manualSorting: true,
    enableStickyHeader: true,
    selectAllMode: false,
    enableBottomToolbar: true,
    enableTopToolbar: false,
    sortDescFirst: true,
    renderBottomToolbar: () =>
      fetchingProductReviewsNext && (
        <div>
          <Progress value={100} striped animate color="rgba(0, 201, 183, 1)" />
        </div>
      ),
    mantineTableHeadRowProps: {
      sx: {
        backgroundColor: "#e5f1f0",
      },
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => {
      return (
        <div>
          <Link to={`/product-details/${row.getValue("id")}`} className="product-review-action-button">
            Review and Publish
          </Link>
        </div>
      );
    },
    positionActionsColumn: "last",
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "",
        size: 150,
      },
      "mrt-row-numbers": {
        Header: "Sl No",
      },
    },
    onSortingChange: setSorting,
    state: { sorting, isLoading: fetchingProductReviews },
    enableHiding: false,
    enableFilters: false,
  });

  return (
    <div>
      <Header title="Product Reviews" />
      <div className="review-products">
        <div className="w-100">
          <div className="d-flex mx-5 my-2" style={{ flexDirection: "row-reverse", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="mx-2"
            >
              <Text size="md" className="mx-2">
                Filter By:
              </Text>
              <Select
                data={[
                  { value: 1, label: "Pending Review" },
                  { value: 0, label: "Published" },
                  { value: 2, label: "Rejected" },
                ]}
                value={currentViewChoice}
                onChange={handleFilterChange}
                styles={{
                  item: {
                    "&[data-selected]": {
                      "&, &:hover": {
                        backgroundColor: "#00c9b7",
                        color: "#fff",
                      },
                    },
                  },
                  input: {
                    ":focus": {
                      borderColor: "#00c9b7",
                    },
                  },
                }}
              />
            </div>
            <div>
              <div className="sort-dropdown-span">
                <div style={{ position: "relative" }}>
                  <div>
                    <Badge
                      color="dark"
                      size="xl"
                      variant="outline"
                      onClick={() => setOpen((open) => !open)}
                      styles={{
                        inner: {
                          fontWeight: "normal",
                          justifyContent: "center",
                          alignItems: "center",
                          textTransform: "none",
                        },
                        root: {
                          backgroundColor: "#fff",
                        },
                      }}
                    >
                      {filterString}
                    </Badge>
                  </div>
                  {openRangePicker && (
                    <div className="product-review-date-picker-container">
                      <DateRangePicker
                        onChange={(item) => {
                          setRange(item.selection);
                        }}
                        editableDateInputs={true}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={[range]}
                        direction="horizontal"
                        className="z-index"
                      />
                      <div className="con-button">
                        <button className="btn-submit" onClick={handleFilterByDate}>
                          Apply
                        </button>
                        <button
                          className="button-cancel"
                          onClick={() => {
                            setOpen(!openRangePicker);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <MantineReactTable table={table} />
        </div>
        <Modal
          opened={opened}
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
          sx={{
            borderRadius: 25,
          }}
          size={"md"}
          centered
          onClose={close}
        >
          <div>Please enter all mandatory fields before proceeding.</div>
          <div className="mt-5" style={{ display: "flex", flexDirection: "row-reverse", gap: 10 }}>
            <Button
              sx={{
                backgroundColor: "#00c9b7",
                color: "#414b4f",
                "&:hover": {
                  backgroundColor: "#00c9b7",
                },
              }}
              onClick={() => {
                history.push(`/product-details/${productId}`);
              }}
            >
              Enter Now
            </Button>
            <Button variant="white" sx={{ color: "#414b4f" }} onClick={close}>
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
