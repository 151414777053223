import * as _ from 'lodash';

import * as Actions from '../actions/physician';

const initialState = {
  products: null,
  productsLoading: false,
  paginationLastRecord: null,
  nextCategoriesSearchPending: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SEARCH_NEXT_CATEGORIES_PENDING:
      return {
        ...state,
        nextCategoriesSearchPending: true
      }
    case Actions.PHYSICIAN_PRODUCT_SEARCH_SUCCESS:
      if (!action.response.last_record) {
        return {
          ...state,
          productsLoading: false,
          paginationLastRecord: null,
          nextCategoriesSearchPending: false
        }
      }

      var transformedData = _.get(action, 'response.results', []).map((item) => {
        const categoryValue = _.get(
          item,
          'docs.hits.hits.0._source.category.name',
          'check_category'
        );
        const categoryCount = _.get(item, 'doc_count', 0);
        const items = _.get(item, 'docs.hits.hits', []);

        const docList = {
          numFound: categoryCount,
          docs: items.map(({ _source }) => ({
            name: _.get(_source, 'name', ''),
            id: _.get(_source, 'id', ''),
            brand: _.get(_source, 'brand.name', ''),
            lastUpdatedAt: _.get(_source, 'updated_at'),
            size: _.get(_source, 'size')
          })),
        };

        return {
          groupValue: categoryValue,
          docList,
        };
      });

      if (state.products) {
        return {
          ...state,
          products: [
            ...state.products,
            ...transformedData
          ],          
          productsLoading: false,
          paginationLastRecord: _.get(action, 'response.last_record', null),
          nextCategoriesSearchPending: false
        }
      }

      return {
        ...state,
        products: transformedData,
        productsLoading: false,
        paginationLastRecord: _.get(action, 'response.last_record', null),
        nextCategoriesSearchPending: false
      };
    case Actions.PHYSICIAN_PRODUCT_SEARCH_STATUS: 
      return {
        ...state,
        productsLoading: _.get(action, 'status'),
      };
    case Actions.CLEAR_PHYSICIAN_PRODUCT:
      return {
        ...state,
        productsLoading: false,
        products: null,
        paginationLastRecord: null,
        nextCategoriesSearchPending: false
      };
    default:
      return state;
  }
};
