import React, { lazy } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.scss';

import PrivateRoute from '../components/common/PrivateRoute';
import PublicRoute from '../components/common/PublicRoute';

// General
import LeftNav from '../components/common/LeftNav';
import Page404 from '../components/common/Page404';
import About from './acds-about/About';

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import ContactUsPage from './acds-contact/ContactUsPage';
import AnalyticsPage from './analytics/AnalyticsPage';
import SSOLoginPage from './auth/SSOLoginPage';
import IngredientsReview from './ingredient-review/IngredientsReview';
import CommentsReviewList from './learning/CommentReviews';
import ProductReviewList from './learning/ProductReviews';
import SampleComponent from './learning/SampleComponent';
import ProductReviewForm from './product-reviews/ProductReviewForm';
library.add(fas);

// Auth Pages
const ForgotPassword = lazy(() => import('./auth/ForgotPassword'));
const ForgotPasswordConfirmation = lazy(() => import('./auth/ForgotPasswordConfirmation'));
const Login = lazy(() => import('./auth/Login'));
const PrivacyPolicy = lazy(() => import('./auth/PrivacyPolicy'));
const RegisterCodes = lazy(() => import('./auth/RegisterCodes'));
const Registration = lazy(() => import('./auth/Registration'));
const RegistrationQuestions = lazy(() => import('./auth/RegistrationQuestions'));
const TermsAndConditions = lazy(() => import('./auth/TermsAndConditions'));
const ChangePassword = lazy(() => import('./profile/ChangePassword'));

// Profile
const Profile = lazy(() => import('./profile/Profile'));
// Allergen Search
const Allergen = lazy(() => import('./physician/Physician'));
// Safe List
const ProductListingPage = lazy(() => import('./safe-list/ProductListingPage'));
// Product
const Product = lazy(() => import('./safe-list/Product'));
// SubmitProduct
const SubmitProduct = lazy(() => import('./safe-list/SubmitProduct'));
// Report Product
const ReportProduct = lazy(() => import('./safe-list/ReportProduct'));
// Report Product Confirmation
const ReportProductConfirmation = lazy(() => import('./safe-list/ReportProductConfirmation'));
// Review Product
const ReviewProduct = lazy(() => import('./safe-list/ReviewProduct'));
// Learning Center
const Learning = lazy(() => import('./learning/Learning'));
// Onboarding
const Onboarding = lazy(() => import('./welcome/Onboarding'));
const Welcome= lazy(() => import('./welcome/Welcome'));


function App () {
  
  return (
    <div className="App">
      <Router>
        <div className="app">
          <LeftNav />
          <div className="router-switch">
            <Switch>
              <Redirect exact from="/" to="/login" />

              {/* Auth pages */}
              <PublicRoute restricted={true} path="/login" component={Login} />
              <PublicRoute restricted={true} path="/forgot-password" component={ForgotPassword} />
              <PublicRoute
                restricted={true}
                path="/forgot-password-confirmation"
                component={ForgotPasswordConfirmation}
              />
              <PublicRoute restricted={true} path="/password-reset/:uid/:token" component={ChangePassword} />
              <PublicRoute restricted={true} path="/register-codes" component={RegisterCodes} />
              <PublicRoute restricted={true} path="/registration" component={Registration} />
              <PrivateRoute path="/registration-questions" component={RegistrationQuestions} userTypes={['patient']} />
              <PublicRoute path="/terms" component={TermsAndConditions} />
              <PublicRoute path="/privacy-policy" component={PrivacyPolicy} />
              <PublicRoute path="/sample" component={SampleComponent} />
              <PublicRoute path="/contact-us" component={ContactUsPage} />
              <PrivateRoute path="/allergen" component={Allergen} userTypes={['physician', 'industry']} />
              <PrivateRoute
                path="/comment-reviews"
                component={CommentsReviewList}
                userTypes={['comment_review']}
              />
              <PrivateRoute
                path="/ingredients-review"
                component={IngredientsReview}
                userTypes={['comment_review']}
              />
              <PrivateRoute
                path="/products-reviews"
                component={ProductReviewList}
                userTypes={['product_review']} />
              <PrivateRoute path="/product-details/:id" component={ProductReviewForm} userTypes={['product_review']}/>
              <PrivateRoute path="/safe-list" component={ProductListingPage} userTypes={['patient']} />
              <PrivateRoute path="/unsafe" component={ProductListingPage} userTypes={['patient']} />
              <PrivateRoute path="/favorites" component={ProductListingPage} userTypes={['patient']} />
              <PrivateRoute path="/product/:id" component={Product} userTypes={['patient', 'physician']} />
              <PrivateRoute path="/submit-product" component={SubmitProduct} userTypes={['patient']} />
              <PrivateRoute path="/report-product" component={ReportProduct} userTypes={['patient']} />
              <PrivateRoute
                path="/report-product-confirmation"
                component={ReportProductConfirmation}
                userTypes={['patient']}
              />
              <PrivateRoute path="/review-product" component={ReviewProduct} userTypes={['patient']} />
              <PrivateRoute path="/onboarding" component={Onboarding} userTypes={['patient']} />
              <PrivateRoute path="/welcome" component={Welcome} userTypes={['patient']} />
              <PrivateRoute path="/learning" component={Learning} userTypes={['user']} />
              <PublicRoute path="/about" component={About} />
              <PrivateRoute path="/profile" component={Profile} userTypes={['patient']} />
              <PrivateRoute path="/analytics" component={AnalyticsPage} userTypes={['analytics']} />
              <Route  path={'/sso/login'}>
                <SSOLoginPage />
              </Route>
              <Route path="*">
                <Page404 />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
