import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import { faProductHunt } from "@fortawesome/free-brands-svg-icons";
import {
  faBook,
  faChartSimple,
  faComments,
  faListAlt,
  faMessage,
  faQuestionCircle,
  faSearch,
  faSignOutAlt,
  faUser,
  faVial,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MTooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { logout } from "acds-react-core";
import { clearSelectedIngredients, resetSearchFilters, setProductSearchText } from "acds-redux-core";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Button, Nav } from "reactstrap";
import logo from "../../assets/images/ACDS-Logo.png";
import { setScrollPosition, setSideBarState } from "../../redux/actions/app-ui";
import { clearCodes, setCurrentActiveTab } from "../../redux/actions/physician";
import { clearProductReviewSearchValue, setCurrentFilterStatus } from "../../redux/actions/productReview";
import { setActiveTab } from "../../redux/actions/safeList";
import "./Header.scss";
import "./LeftNav.scss";

export default function LeftNav() {
  const CUSTOM_TOOLTIP_PLACEMENT = "bottom-end";

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const user = useSelector((s) => s.account.user);
  const sideBarOpen = useSelector((s) => s.appUI.sideBarOpen);
  const currentPhysicianSearchActiveTab = useSelector((state) => state.physician.activeTab);
  const productReviewSearch = useSelector((state) => state.productReviews.productReviewSearch);

  const groups = _.get(user, "groups");
  const userGroup = _.get(user, "groups.0.name");

  const userGroups = _.map(groups, (group) => group.name);

  const [logoutModal, setLogoutModal] = useState(false);

  const CustomTooltip = styled(({ className, ...props }) => (
    <MTooltip {...props} arrow placement={CUSTOM_TOOLTIP_PLACEMENT} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const toggle = () => {
    dispatch(setSideBarState(!sideBarOpen));
  };

  const handleLogout = () => {
    dispatch(logout());
    setLogoutModal(false);
    history.push("/login");
  };

  const handleListProductReset = () => {
    // This 'if' check is written to solve a bug regarding clicking 'Safe List' again while in the 'Safe List' tab.
    // The page was continuously in loading state when 'Safe List' was clicked twice in a row.
    if (location.pathname === "/safe-list") return;
    dispatch(resetSearchFilters());
    dispatch(setProductSearchText(""));
    dispatch(setActiveTab(null));
    dispatch(setScrollPosition(null));
  };

  useEffect(() => {
    const { pathname } = location;

    const isProductReviewNavigation = pathname.includes("product-details") || pathname.includes("products-reviews");

    if (!isProductReviewNavigation) {
      if (!_.isEmpty(productReviewSearch)) {
        dispatch(clearProductReviewSearchValue());
      }
    }
  }, [location]);

  return (
    <div
      className="left-nav bg-nav"
      style={{
        position: "fixed",
        top: "0px",
        height: "100vh",
      }}
    >
      <Nav className="left-nav__nav">
        <NavLink className="header-logo" to="/" onClick={handleListProductReset}>
          <img src={logo} alt="ACDS Home Page" />
        </NavLink>
        {(userGroups.includes("physician") || userGroups.includes("industry")) && (
          <NavLink
            className="nav-link"
            to="/allergen"
            activeClassName="selected"
            hidden={!user}
            onClick={() => {
              dispatch(clearCodes());
              dispatch(clearSelectedIngredients());
              if (currentPhysicianSearchActiveTab === "code-management") {
                dispatch(setCurrentActiveTab("search"));
              }
            }}
          >
            <CustomTooltip title={sideBarOpen ? "" : "Allergen Search"}>
              <FontAwesomeIcon className="font-awesome-icon" icon={faSearch} size="lg" id="allergenSearch" />
            </CustomTooltip>
            <span>
              Allergen <br /> Search
            </span>
          </NavLink>
        )}
        {userGroups.includes("patient") && (
          <NavLink
            className="nav-link"
            to="/safe-list"
            activeClassName="selected"
            hidden={!user}
            onClick={handleListProductReset}
          >
            <CustomTooltip title={sideBarOpen ? "" : "Safe List"}>
              <FontAwesomeIcon className="font-awesome-icon" icon={faListAlt} size="lg" id="safeList" />
            </CustomTooltip>
            <span>Safe List</span>
          </NavLink>
        )}
        {(userGroups.includes("patient") || userGroups.includes("physician")) && (
          <NavLink className="nav-link" to="/learning" activeClassName="selected" onClick={handleListProductReset}>
            <CustomTooltip title={sideBarOpen ? "" : "Learning Center"}>
              <FontAwesomeIcon className="font-awesome-icon" icon={faBook} size="lg" id="learningCenter" />
            </CustomTooltip>
            <span>
              Learning <br /> Center
            </span>
          </NavLink>
        )}
        {userGroups.includes("product_review") && (
          <NavLink
            className="nav-link"
            to="/products-reviews"
            activeClassName="selected"
            hidden={!user}
            onClick={() => {
              dispatch(setCurrentFilterStatus(1));
            }}
          >
            <CustomTooltip title={sideBarOpen ? "" : "Product Reviews"}>
              <FontAwesomeIcon className="font-awesome-icon" icon={faProductHunt} size="lg" />
            </CustomTooltip>
            <span>
              Product <br /> Reviews
            </span>
          </NavLink>
        )}
        {userGroups.includes("analytics") && (
          <NavLink className="nav-link" to="/analytics" activeClassName="selected" hidden={!user}>
            <CustomTooltip title={sideBarOpen ? "" : "Analytics"}>
              <FontAwesomeIcon className="font-awesome-icon" icon={faChartSimple} size="lg" id="analytics" />
            </CustomTooltip>
            <span>Analytics</span>
          </NavLink>
        )}
        {userGroups.includes("comment_review") && (
          <NavLink className="nav-link" to="/comment-reviews" activeClassName="selected" hidden={!user}>
            <CustomTooltip title={sideBarOpen ? "" : "Comment Reviews"}>
              <FontAwesomeIcon className="font-awesome-icon" icon={faComments} size="lg" id="commentReviews" />
            </CustomTooltip>
            <span>
              Comment <br /> Reviews
            </span>
          </NavLink>
        )}
        {userGroups.includes("ingredient_review") && (
          <NavLink className="nav-link" to="/ingredients-review" activeClassName="selected" hidden={!user}>
            <CustomTooltip title={sideBarOpen ? "" : "Ingredient Reviews"}>
              <FontAwesomeIcon className="font-awesome-icon" icon={faVial} size="lg" id="ingredientReviews" />
            </CustomTooltip>
            <span>
              Ingredient <br /> Reviews
            </span>
          </NavLink>
        )}
        {userGroups.includes("patient") && (
          <NavLink
            className="nav-link"
            to="/profile"
            activeClassName="selected"
            hidden={!user}
            onClick={handleListProductReset}
          >
            <CustomTooltip title={sideBarOpen ? "" : "My Profile"} arrow>
              <FontAwesomeIcon className="font-awesome-icon" icon={faUser} size="lg" id="myProfile" />
            </CustomTooltip>
            <span>My Profile</span>
          </NavLink>
        )}
        <NavLink className="nav-link" to="/about" activeClassName="selected" onClick={handleListProductReset}>
          <CustomTooltip title={sideBarOpen ? "" : "About ACDS"}>
            <FontAwesomeIcon className="font-awesome-icon" icon={faQuestionCircle} size="lg" id="about" />
          </CustomTooltip>
          <span>About ACDS</span>
        </NavLink>
        <NavLink className="nav-link" to={"/contact-us"} activeClassName="selected" onClick={handleListProductReset}>
          <CustomTooltip title={sideBarOpen ? "" : "Contact Us"}>
            <FontAwesomeIcon icon={faMessage} className="font-awesome-icon" size="lg" id="contactUs" />
          </CustomTooltip>
          Contact Us
        </NavLink>
        {userGroups.length > 0 && (
          <Button
            className="btn btn-left-nav"
            onClick={() => {
              setLogoutModal(true);
            }}
          >
            <CustomTooltip title={sideBarOpen ? "" : "Logout"} arrow>
              <FontAwesomeIcon className="font-awesome-icon" icon={faSignOutAlt} size="lg" id="navLogOut" />
            </CustomTooltip>
            <span>Logout</span>
          </Button>
        )}
      </Nav>
      <CModal
        backdrop="static"
        visible={logoutModal}
        onClose={() => setLogoutModal(false)}
        aria-labelledby="StaticBackdropExampleLabel"
        alignment="center"
        className="nav-logout-modal"
      >
        <CModalHeader className="logout-header" style={{ borderBottom: "none" }}>
          <CModalTitle id="logoutHeader">
            <span className="logout-header-txt">Logout confirmation</span>
          </CModalTitle>
        </CModalHeader>
        <CModalBody className="pt-0" style={{ borderBottom: "none" }}>
          Are you sure you want to logout?
        </CModalBody>
        <CModalFooter style={{ borderTop: "none" }}>
          <CButton color="secondary" onClick={() => setLogoutModal(false)}>
            Cancel
          </CButton>
          <CButton color="primary" onClick={handleLogout}>
            Confirm
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
}
