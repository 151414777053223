import { accountSagas, setEnv } from 'acds-react-core';
import {
  accountCoreSagas,
  ingredientSagas,
  learningSagas,
  notificationSettingsSagas,
  qualityOfLifeSagas,
  questionsSagas,
  safeListSagas,
  searchSagas,
} from 'acds-redux-core';
import { all, call, put, spawn } from 'redux-saga/effects';

import { default as appAccountSagas } from './sagas/account';
import { default as authSagas } from './sagas/auth';
import { default as commentReviewSagas } from './sagas/commentReview';
import { default as commonUtilsSagas } from './sagas/commonUtils';
import { default as ingredientReviewsSagas } from './sagas/ingredientReview';
import { default as learningCenterSagas } from './sagas/learningCenter';
import { default as notificationSagas } from './sagas/notifications';
import { default as physicianSagas } from './sagas/physician';
import { default as plotSagas } from './sagas/plot';
import { default as productReviewSagas } from './sagas/productReviews';
import { default as profileSagas } from './sagas/profile';
import { default as routingAndToastSagas } from './sagas/routingAndToast';
import { default as safeListLocalSagas } from './sagas/safeList';

function* initEnv () {
  yield put(
    setEnv({
      api_domain: process.env.REACT_APP_API_DOMAIN,
      api_root: process.env.REACT_APP_API_ROOT,
      token_name: process.env.REACT_APP_TOKEN_NAME,
      esRoot: process.env.REACT_APP_ELASTIC_SEARCH_ROOT,
    }),
  );
}

function* rootSaga () {
  yield call(initEnv);

  const sagas = [
    // acds-react-core
    accountSagas,
    // acds-redux-core
    accountCoreSagas,
    ingredientSagas,
    learningSagas,
    safeListSagas,
    searchSagas,
    qualityOfLifeSagas,
    questionsSagas,
    notificationSettingsSagas,
    // local-redux
    authSagas,
    appAccountSagas,
    profileSagas,
    safeListLocalSagas,
    physicianSagas,
    notificationSagas,
    routingAndToastSagas,
    commonUtilsSagas,
    learningCenterSagas,
    plotSagas,
    productReviewSagas,
    commentReviewSagas,
    ingredientReviewsSagas
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.error('Root Saga Error: ', e);
          }
        }
      }),
    ),
  );
}

export default rootSaga;
