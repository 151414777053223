import { apiDelete, apiGet, apiPatch, apiPost } from "acds-react-core";
import _ from "lodash";
import { toast } from "react-toastify";
import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  DO_UPDATE_PRODUCT_REVIEW_PHOTOS,
  GET_PRODUCT_NEXT_REVIEWS_ERROR,
  GET_PRODUCT_NEXT_REVIEWS_SUCCESS,
  GET_PRODUCT_REVIEWS,
  GET_PRODUCT_REVIEWS_ERROR,
  GET_PRODUCT_REVIEWS_NEXT,
  GET_PRODUCT_REVIEWS_SUCCESS,
  GET_PRODUCT_REVIEW_LAST_UPDATED_DATE,
  GET_SELECTED_PRODUCT_DETAILS,
  GET_SELECTED_PRODUCT_DETAILS_SUCCESS,
  PRODUCT_REVIEW_UPLOAD_PRODUCT_PHOTO_ERROR,
  PRODUCT_REVIEW_UPLOAD_PRODUCT_PHOTO_SUCCESS,
  PUBLISH_SELECTED_PRODUCT_DETAILS,
  SET_PRODUCT_REVIEW_LAST_UPDATED_DATE,
  UPDATE_PRODUCT_REVIEW_STATUS,
  UPDATE_SELECTED_PRODUCT_DETAILS,
  UPDATE_SELECTED_PRODUCT_DETAILS_SUCCESS,
} from "../actions/productReview";

function* doGetProductReviewLastUpdatedDate(payload) {
  const status = _.get(payload, "payload", 1);
  const apiUrl = `api/products/last_updated_date/?status=${status}`;
  try {
    const response = yield apiGet(apiUrl);
    yield put({
      type: SET_PRODUCT_REVIEW_LAST_UPDATED_DATE,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* doGetProductReviews(payload) {
  const baseUrl = yield select((state) => state.env.api_root);

  const productReviewApiPath = "/api/products";

  const listProductReviews = new URL(productReviewApiPath, baseUrl);

  if (_.has(payload, "payload.startDate")) {
    yield put({
      type: SET_PRODUCT_REVIEW_LAST_UPDATED_DATE,
      response: {
        from: _.get(payload, "payload.startDate"),
        to: _.get(payload, "payload.endDate"),
      },
    });
    listProductReviews.searchParams.set("updated_at__lte", _.get(payload, "payload.endDate"));
    listProductReviews.searchParams.set("updated_at__gte", _.get(payload, "payload.startDate"));
  } else {
    listProductReviews.searchParams.set("ordering", _.get(payload, "payload.sort"));
  }

  listProductReviews.searchParams.set("status", _.get(payload, "payload.currentViewChoice"));

  const apiUrl = _.replace(listProductReviews.toString(), baseUrl + "/", "");

  try {
    const listProductReview = yield apiGet(apiUrl);

    yield put({
      type: GET_PRODUCT_REVIEWS_SUCCESS,
      result: listProductReview,
    });
  } catch (error) {
    yield put({
      type: GET_PRODUCT_REVIEWS_ERROR,
      error,
    });
    console.log(error);
  }
}

function* doGetProductReviewsNext() {
  const baseUrl = yield select((state) => state.env.api_root);
  const fetchingProductReviews = yield select((state) => state.productReviews.fetchingProductReviews);
  const productReviewsNext = yield select((state) => state.productReviews.productReviewsNext);
  const productReviewsNextUrl = _.replace(productReviewsNext, baseUrl + "/", "");

  if (fetchingProductReviews) {
    return;
  }

  if (productReviewsNext.length > 0) {
    try {
      const productReviewNextResult = yield apiGet(productReviewsNextUrl);

      yield put({
        type: GET_PRODUCT_NEXT_REVIEWS_SUCCESS,
        result: productReviewNextResult,
      });
    } catch (error) {
      yield put({
        type: GET_PRODUCT_NEXT_REVIEWS_ERROR,
        error,
      });
      console.log(error);
    }
  }
}

function* doUpdateProductStatus(action) {
  const params = action;
  const id = params.payload.id;
  const payload = {
    status: params.payload.status,
  };

  const apiString = `api/products/${id}/`;

  try {
    yield apiPatch(apiString, payload);

    if (params.payload.status === 2) {
      toast.error("Product Rejected", {
        bodyClassName: "toast-body",
        style: {
          backgroundColor: "#414B4F",
          color: "#ffffff",
        },
        progressStyle: {
          background: "#E74C3C",
        },
        closeButton: true,
        position: "top-right",
      });
    } else if (params.payload.status === 0) {
      toast.success("Product Published", {
        bodyClassName: "toast-body",
        style: {
          backgroundColor: "#414B4F",
          color: "#ffffff",
        },
        progressStyle: {
          background: "#00c9b7",
        },
        closeButton: true,
        position: "top-right",
      });
    } else {
      toast.success("Product Saved", {
        bodyClassName: "toast-body",
        style: {
          backgroundColor: "#414B4F",
          color: "#ffffff",
        },
        progressStyle: {
          background: "#00c9b7",
        },
        closeButton: true,
        position: "top-right",
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* doSaveProductDetails(action) {
  const params = action;
  const payload = _.omit(params.payload.payload, ["images"]);
  const productId = payload.productId;
  const productImages = _.get(params, "payload.payload.images", {});

  const selectedProduct = yield select((state) => state.productReviews.selectedProduct);

  const apiString = `api/products/${productId}/`;

  try {
    const updatedProductImages = Object.keys(productImages);

    if (updatedProductImages.length > 0) {
      yield all(
        _.map(updatedProductImages, (value) => {
          const productObject = _.get(productImages, value, {});

          const oldImage = _.get(productObject, "image", {});
          const newImage = _.get(productObject, "newImage", {});

          if (newImage?.size > 0) {
            return put({
              type: DO_UPDATE_PRODUCT_REVIEW_PHOTOS,
              position: productObject.position,
              productId,
              method: _.has(selectedProduct, `images.${productObject.position}.id`) ? "patch" : "post",
              imageId: _.get(selectedProduct, `images.${productObject.position}.id`, ""),
              photo: newImage,
            });
          } else if (_.isEmpty(oldImage) && !newImage?.size) {
            if (_.has(selectedProduct, `images.${productObject.position}.id`)) {
              return put({
                type: DO_UPDATE_PRODUCT_REVIEW_PHOTOS,
                position: productObject.position,
                method: "delete",
                imageId: _.get(selectedProduct, `images.${productObject.position}.id`, ""),
              });
            }
          }
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }

  try {
    yield apiPatch(apiString, payload);

    toast.success("Product Saved", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#00c9b7",
      },
      closeButton: true,
      position: "top-right",
    });

    yield put({
      type: UPDATE_SELECTED_PRODUCT_DETAILS_SUCCESS,
    });
    params.payload.history.push("/products-reviews");
  } catch (error) {
    console.error(error);
  }
}

function* doGetSelectedProductDetails(action) {
  const productId = action.productId;
  try {
    const response = yield apiGet(`api/products/product-list/${productId}/`);

    yield put({
      type: GET_SELECTED_PRODUCT_DETAILS_SUCCESS,
      response,
    });
  } catch (error) {
    console.error(error);
  }
}

function* doProductImageUpload({ photo, productId, position, method, imageId }) {
  const formData = new FormData();

  if (method === "post") {
    formData.append("product", productId);
  }

  formData.append("position", position);
  formData.append("image", photo);

  try {
    let response;
    if (method == "post") {
      response = yield apiPost("api/products/upload/", {
        data: formData,
        headers: { "Content-type": "multipart/form-data" },
      });
    } else if (method === "patch") {
      response = yield apiPatch(`api/products/upload/${imageId}/`, {
        data: formData,
        headers: { "Content-type": "multipart/form-data" },
      });
    } else if (method === "delete") {
      response = yield apiDelete(`api/products/upload/${imageId}/`);
    }

    yield put({
      type: PRODUCT_REVIEW_UPLOAD_PRODUCT_PHOTO_SUCCESS,
      response,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: PRODUCT_REVIEW_UPLOAD_PRODUCT_PHOTO_ERROR,
      error: error.response.data,
    });
  }
}

function* doPublishProduct(action) {
  const params = action;

  const payload = _.omit(params.payload.payload, ["images"]);
  const productId = payload.productId;
  const productImages = _.get(params, "payload.payload.images", {});
  const selectedProduct = yield select((state) => state.productReviews.selectedProduct);

  const apiString = `api/products/${productId}/`;

  if (payload.status === 0) {
    try {
      const updatedProductImages = Object.keys(productImages);

      if (updatedProductImages.length > 0) {
        yield all(
          _.map(updatedProductImages, (value) => {
            const productObject = _.get(productImages, value, {});

            const oldImage = _.get(productObject, "image", {});
            const newImage = _.get(productObject, "newImage", {});

            if (newImage?.size > 0) {
              return put({
                type: DO_UPDATE_PRODUCT_REVIEW_PHOTOS,
                position: productObject.position,
                productId,
                method: _.has(selectedProduct, `images.${productObject.position}.id`) ? "patch" : "post",
                imageId: _.get(selectedProduct, `images.${productObject.position}.id`, ""),
                photo: newImage,
              });
            } else if (_.isEmpty(oldImage) && !newImage?.size) {
              if (_.has(selectedProduct, `images.${productObject.position}.id`)) {
                return put({
                  type: DO_UPDATE_PRODUCT_REVIEW_PHOTOS,
                  position: productObject.position,
                  method: "delete",
                  imageId: _.get(selectedProduct, `images.${productObject.position}.id`, ""),
                });
              }
            }
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  try {
    yield apiPatch(apiString, payload);
    if (payload.status === 0) {
      toast.success("Product has been published", {
        bodyClassName: "toast-body",
        style: {
          backgroundColor: "#414B4F",
          color: "#ffffff",
        },
        progressStyle: {
          background: "#00c9b7",
        },
        closeButton: true,
        position: "top-right",
      });
    } else {
      toast.error("Product Rejected", {
        bodyClassName: "toast-body",
        style: {
          backgroundColor: "#414B4F",
          color: "#ffffff",
        },
        progressStyle: {
          background: "#E74C3C",
        },
        closeButton: true,
        position: "top-right",
      });
    }

    yield put({
      type: UPDATE_SELECTED_PRODUCT_DETAILS_SUCCESS,
    });
    params.payload.history.push("/products-reviews");
  } catch (error) {
    console.error(error);
  }
}

export default function* productReviewSagas() {
  yield takeLatest(GET_PRODUCT_REVIEW_LAST_UPDATED_DATE, doGetProductReviewLastUpdatedDate);
  yield takeLatest(GET_PRODUCT_REVIEWS, doGetProductReviews);
  yield takeLatest(GET_PRODUCT_REVIEWS_NEXT, doGetProductReviewsNext);
  yield takeEvery(UPDATE_PRODUCT_REVIEW_STATUS, doUpdateProductStatus);
  yield takeLatest(GET_SELECTED_PRODUCT_DETAILS, doGetSelectedProductDetails);
  yield takeLatest(UPDATE_SELECTED_PRODUCT_DETAILS, doSaveProductDetails);
  yield takeLatest(DO_UPDATE_PRODUCT_REVIEW_PHOTOS, doProductImageUpload);
  yield takeLatest(PUBLISH_SELECTED_PRODUCT_DETAILS, doPublishProduct);
}
