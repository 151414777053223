/* eslint-disable no-case-declarations */
import * as _ from 'lodash';
import * as Actions from '../actions/safeList';

const initialState = {
  activeTab: {},
  getCategoriesLoading: false,
  productCategories: null,
  productCategoriesError: null,
  submitProductError: null,
  uploadProductPhotoError: null,
  viewBy: 'Category',
  unsafeIngredients: [],
  productBrandsLoading: false,
  productBrands: [],
  productBrandNext: '',
  productManufacturesLoading: false,
  productManufactures: [],
  productManufacturesNext: '',
  addProductSubmitting: false,
  categoriesFlattened:[],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Product Categories
    case Actions.GET_PRODUCT_CATEGORIES:
      return {
        ...state,
        getCategoriesLoading: true,
      };
    case Actions.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: _.get(action, 'category'),
      };
    case Actions.SET_VIEW_BY_TYPE:
      return {
        ...state,
        viewBy: _.get(action, 'viewBy'),
      };
    case Actions.GET_PRODUCT_CATEGORIES_SUCCESS:
      const flattenList = (list) => {
        const flattened = _.flattenDeep(
          list.map((item) => {
            if (item.children.length === 0) {
              return item;
            } else {
              return [item, ...flattenList(item.children)];
            }
          }),
        );

        return flattened;
      };

      const flattenedList = flattenList(_.get(action, 'response', []));

      return {
        ...state,
        getCategoriesLoading: false,
        productCategories: _.get(action, 'response'),
        categoriesFlattened: flattenedList,
      };
    case Actions.GET_PRODUCT_CATEGORIES_ERRORS:
      return {
        ...state,
        getCategoriesLoading: false,
        productCategoriesError: action.error,
      };
    case Actions.POST_SUBMIT_PRODUCT_ERRORS:
      return {
        ...state,
        submitProductError: action.error,
      };
    case Actions.UPLOAD_PRODUCT_PHOTO_ERRORS:
      return {
        ...state,
        uploadProductPhotoError: action.error,
      };
    case Actions.GET_UNSAFE_INGREDIENTS:
      return {
        ...state,
      };
    case Actions.SET_UNSAFE_INGREDIENTS:
      return {
        ...state,
        unsafeIngredients: [...action.payload],
      };
    case Actions.EMPTY_UNSAFE_INGREDIENTS:
      return {
        ...state,
        unsafeIngredients: [],
      };
    case Actions.GET_PRODUCT_BRANDS:
      return {
        ...state,
        productBrandsLoading: true
      }
    case Actions.GET_PRODUCT_BRANDS_SUCCESS:
      return {
        ...state,
        productBrands: action.response.data,
        productBrandNext: action.response.next,
        productBrandsLoading: false
      };
    case Actions.GET_PRODUCT_MANUFACTURERS:
      return {
        ...state,
        productManufacturesLoading: true
      }
    case Actions.GET_PRODUCT_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        productManufactures: action.response.data,
        productManufacturesNext: action.response.next,
        productManufacturesLoading: false
      };
    case Actions.SET_ADD_PRODUCT_SUBMITTING:
      return {
        ...state,
        addProductSubmitting: action.value,
      };
    default:
      return state;
  }
};
