import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card, Divider, Grid, Input, ScrollArea, Text, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { getElasticSearch } from 'acds-redux-core';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Select from 'react-select';
import Header from '../../components/common/Header';
import LoadingScreen from '../../components/common/LoadingScreen';
import {
  getSelectedProductDetails,
  resetSelectedProducts,
  saveProductDetails,
  updateProductReviewProduct,
} from '../../redux/actions/productReview';
import { 
  getProductBrands, 
  getProductBrandsNext,
  getProductManufacturesNext,
  getProductManufactures,
  safelistGetProductCategories
} from '../../redux/actions/safeList';
import AdditionalImageReviewComponent from './AdditionalImageComponet';
import ProductReviewImage from './ProductReviewImage';

const initialFormValues = {
  name: '',
  description: '',
  barcode: '',
  brand: '',
  submittingUser: '',
  category: '',
  manufacturer: '',
  ingredient: [],
  related_product: '',
  status: '',
  categoryRoot: '',
  categoryBranch: '',
  categoryLeaf: '',
  createdAt: '',
  updatedAt: '',
  productPhoto1: '',
  ingredientsPhoto: '',
  barCodePhoto: '',
  additionalProductImage1: '',
  additionalProductImage2: '',
};

export default function ProductReviewForm () {
  const LABEL_COL = 2;
  const FIELD_COL = 7;
  const DAYJS_FORMAT = 'MMM DD, YYYY, h:mm a';

  const productImageFields = [
    {
      name: 'productPhoto1',
      label: 'Product Image(Front)',
      required: true,
      formFieldName: 'productPhoto1',
      imageIdentifier: 'Thumbnail Image',
      position: 0,
    },
    {
      name: 'ingredientsPhoto',
      label: 'Ingredients',
      required: true,
      formFieldName: 'ingredientsPhoto',
      imageIdentifier: 'Ingredients Image',
      position: 1,
    },
    {
      name: 'barCodePhoto',
      label: 'Barcode',
      required: true,
      formFieldName: 'barCodePhoto',
      imageIdentifier: 'Barcode Image',
      position: 2,
    },
  ];

  const dispatch = useDispatch();
  const history = useHistory();

  const match = useRouteMatch('/product-details/:id');
  const productId = _.get(match, 'params.id');

  const getCategoriesLoading = useSelector((s) => s.safeList.getCategoriesLoading);
  const categories = useSelector((s) => s.safeList.productCategories);
  const categoriesFlattened = useSelector((state) => state.safeList.categoriesFlattened);  

  const BRAND_SEARCH_OFFSET = useRef(20);
  const BRAND_SEARCH_LIMIT = 20;
  const MANUFACTURER_SEARCH_OFFSET = useRef(20);
  const MANUFACTURER_SEARCH_LIMIT = 20;

  const brands = useSelector((s) => s.safeList.productBrands);
  const manufacturer = useSelector((s) => s.safeList.productManufactures);
  const isFetchingProductDetails = useSelector((state) => state.productReviews.fetchingSelectedProductDetails);
  const selectedProduct = useSelector((state) => state.productReviews.selectedProduct);
  const updatingCurrentProductDetails = useSelector((state) => state.productReviews.updatingCurrentProductDetails);
  const updatingProductStatus = useSelector((state) => state.productReviews.updatingProductStatus);

  useEffect(() => {
    dispatch(getSelectedProductDetails(productId));
  }, []);

  const [categoriesBranch, setCategoriesBranch] = useState([]);
  const [categoriesLeaf, setCategoriesLeaf] = useState([]);

  const [brandSearchValue, setBrandSearchValue] = useState('');
  const [manufacturerSearchValue, setManufacturerSearchValue] = useState('');

  const [ingredientSearchValue, setIngredientSearchValue] = useState('');
  const [debounced] = useDebouncedValue(ingredientSearchValue, 300);

  const selectIngredientViewPort = useRef(null);
  const selectCategoryRootInputRef = useRef(null);
  const selectCategoryBranchInputRef = useRef(null);
  const selectCategoryLeafInputRef = useRef(null);
  const nameInputRef = useRef(null);
  const submittingUserRef = useRef(null);
  const ingredientRef = useRef(null);

  useEffect(() => {
    dispatch(getProductBrands({ searchValue: brandSearchValue }));
  }, [brandSearchValue]);

  useEffect(() => {
    dispatch(getProductManufactures({ searchValue: manufacturerSearchValue }));
  }, [manufacturerSearchValue]);

  useEffect(() => {
    dispatch(safelistGetProductCategories());
  }, []);

  useEffect(() => {
    dispatch(
      getElasticSearch({
        queryParams: {
          fuzzy: true,
          limit: 250,
          ...(debounced && { name: debounced }),
        },
        searchRedux: 'ingredientFuzzySearch',
        searchIndex: 'ingredient',
      }),
    );
  }, [debounced]);

  const { getInputProps, values, setValues, errors, setFieldError, resetDirty, isDirty } = useForm({
    initialValues: initialFormValues,
  });

  const ingredients = useSelector((s) => s.ingredientReducer.ingredients);
  const selectedIngredientsArray = _.map(values.ingredient, 'id');
  const filteredIngredients = _.reject(ingredients, (item) => selectedIngredientsArray.includes(item.id));

  useEffect(() => {
    if (Object.keys(selectedProduct).length > 0 && !getCategoriesLoading) {
      let selectCategoryRoot = '';
      let selectCategoryBranch = '';
      let selectCategoryLeaf = '';

      if (_.has(selectedProduct, 'category')) {
        const category = _.find(categoriesFlattened, ['id', selectedProduct.category], {});

        if (!_.isEmpty(category)) {
          if (_.isEmpty(category.grandparent_category === null ? '' : category.grandparent_category.toString())) {
            const rootCategoryDetails = _.find(categoriesFlattened, ['id', category.parent_category]);
            setCategoriesBranch(_.get(rootCategoryDetails, 'children', []));
            selectCategoryRoot = rootCategoryDetails.id;
            selectCategoryBranch = category.id;
          } else {
            const branchCategoryDetails = _.find(categoriesFlattened, ['id', category.parent_category]);
            const rootCategoryDetails = _.find(categoriesFlattened, ['id', category.grandparent_category]);

            setCategoriesLeaf(_.get(branchCategoryDetails, 'children', []));
            setCategoriesBranch(_.get(rootCategoryDetails, 'children', []));

            selectCategoryRoot = rootCategoryDetails.id;
            selectCategoryBranch = branchCategoryDetails.id;
            selectCategoryLeaf = category.id;
          }
        }
      }

      setValues({
        name: _.get(selectedProduct, 'name', ''),
        description: _.get(selectedProduct, 'description', ''),
        barcode: _.get(selectedProduct, 'barcode', ''),
        submittingUser: _.get(selectedProduct, 'submitting_user', 'Admin'),
        ingredient: selectedProduct['ingredient'] ?? [],
        status: _.get(selectedProduct, 'status', ''),
        categoryRoot: selectCategoryRoot,
        categoryBranch: selectCategoryBranch,
        categoryLeaf: selectCategoryLeaf,
        createdAt: _.get(selectedProduct, 'created_at', null),
        updatedAt: _.get(selectedProduct, 'updated_at', null),
        brand: _.isEmpty(selectedProduct.brand) ? null : { id: 'default-value', name: selectedProduct.brand },
        manufacturer: _.isEmpty(selectedProduct.manufacturer)
          ? null
          : { id: 'default-value', name: selectedProduct.manufacturer },
        productPhoto1: {
          position: 0,
          image: _.get(selectedProduct, 'images.0', {}),
        },
        ingredientsPhoto: {
          position: 1,
          image: _.get(selectedProduct, 'images.1', {}),
        },
        barCodePhoto: {
          position: 2,
          image: _.get(selectedProduct, 'images.2', {}),
        },
        additionalProductImage1: {
          position: 3,
          image: _.get(selectedProduct, 'images.3', {}),
        },
        additionalProductImage2: {
          position: 4,
          image: _.get(selectedProduct, 'images.4', {}),
        },
      });

      resetDirty();
    }
  }, [selectedProduct, getCategoriesLoading]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedProducts());
    };
  }, []);

  if (isFetchingProductDetails || getCategoriesLoading) {
    return <LoadingScreen />;
  }

  const handleFormValidation = () => {
    const imageFieldsToCheck = [
      'productPhoto1',
      'ingredientsPhoto',
      'barCodePhoto',
      'additionalProductImage1',
      'additionalProductImage2',
    ];
    
    let validationFormCategory;

    const payload = {
      status: 0,
      images: {},
      productId: +productId,
    };

    if (!(values.name.length > 0)) {
      setFieldError('name', 'Product name is a required field');
      nameInputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      return;
    } else {
      payload['name'] = values.name;
    }

    if (_.isEmpty(values.brand)) {
      setFieldError('brand', 'Please select a brand');
      submittingUserRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      return;
    } else {
      if (values.brand.id !== 'default-value') {
        payload['brand'] = values.brand.id;
      }
    }

    if (values.categoryRoot === null || values.categoryRoot === '' || values.categoryRoot === undefined) {
      setFieldError('categoryRoot', 'Please select a base category');
      submittingUserRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      return;
    }

    if (
      categoriesBranch.length > 0 &&
      (values?.categoryBranch === null || values?.categoryBranch === '' || values?.categoryBranch === undefined)
    ) {
      setFieldError('categoryBranch', 'Please select a sub category');
      submittingUserRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      return;
    }

    if (
      categoriesLeaf.length > 0 &&
      (values?.categoryLeaf === null || values?.categoryLeaf === '' || values?.categoryLeaf === undefined)
    ) {
      setFieldError('categoryLeaf', 'Please select a sub category');
      submittingUserRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      return;
    }

    if (_.isEmpty(values.manufacturer)) {
      payload['manufacturer'] = null;
    } else {
      if (values.manufacturer.id !== 'default-value') {
        payload['manufacturer'] = values.manufacturer.id;
      }
    }

    if (values.ingredient.length === 0) {
      setFieldError('ingredient', 'Please select at least one ingredient');
      ingredientRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      return;
    } else {
      payload['ingredient'] = _.map(values.ingredient, 'id');
    }

    if (values.categoryLeaf !== '' && !isNaN(values.categoryLeaf) && values.categoryLeaf !== null) {
      validationFormCategory = values.categoryLeaf;
    } else if (values.categoryBranch !== '' && !isNaN(values.categoryBranch) && values.categoryBranch !== null) {
      validationFormCategory = values.categoryBranch;
    } else if (values.categoryRoot !== '' && !isNaN(values.categoryRoot) && values.categoryRoot !== null) {
      validationFormCategory = values.categoryRoot;
    }

    payload['category'] = validationFormCategory;

    imageFieldsToCheck.forEach((field) => {
      if (isDirty(field)) {
        payload.images[field] = values[field];
      }
    });

    dispatch(
      updateProductReviewProduct({
        payload,
        history,
      }),
    );
  };

  const handleSave = () => {
    let saveCategory;

    const payload = {
      images: {},
      status: 1,
      productId: +productId,
    };

    const nestedFieldsToCheck = ['brand', 'manufacturer'];
    const fieldsToCheck = ['description', 'barcode'];
    const imageFieldsToCheck = [
      'productPhoto1',
      'ingredientsPhoto',
      'barCodePhoto',
      'additionalProductImage1',
      'additionalProductImage2',
    ];

    nestedFieldsToCheck.forEach((field) => {
      if (isDirty(field)) {
        if (values[field]?.id === 'default-value') return;
        if(_.isEmpty(values[field])) {
          payload[field] = null;
        } else {
          payload[field] = values[field].id;
        }
      }
    });

    fieldsToCheck.forEach((field) => {
      if (isDirty(field)) {
        payload[field] = values[field];
      }
    });

    imageFieldsToCheck.forEach((field) => {
      if (isDirty(field)) {
        payload.images[field] = values[field];
      }
    });

    if (!(values.name.length > 0)) {
      setFieldError('name', 'Product name is a required field');
      nameInputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      return;
    } else {
      payload['name'] = values.name;
    }

    if (
      categoriesBranch.length > 0 &&
      (values?.categoryBranch === null || values?.categoryBranch === '' || values?.categoryBranch === undefined)
    ) {
      setFieldError('categoryBranch', 'Please select a sub category');
      submittingUserRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      return;
    }

    if (
      categoriesLeaf.length > 0 &&
      (values?.categoryLeaf === null || values?.categoryLeaf === '' || values?.categoryLeaf === undefined)
    ) {
      setFieldError('categoryLeaf', 'Please select a sub category');
      submittingUserRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      return;
    }

    if (values.ingredient.length === 0) {
      setFieldError('ingredient', 'Please select at least one ingredient');
      ingredientRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      return;
    } else {
      payload['ingredient'] = _.map(values.ingredient, 'id');
    }

    if (values.categoryLeaf !== '' && !isNaN(values.categoryLeaf) && values.categoryLeaf !== null) {
      saveCategory = values.categoryLeaf;
    } else if (values.categoryBranch !== '' && !isNaN(values.categoryBranch) && values.categoryBranch !== null) {
      saveCategory = values.categoryBranch;
    } else if (values.categoryRoot !== '' && !isNaN(values.categoryRoot) && values.categoryRoot !== null) {
      saveCategory = values.categoryRoot;
    } else {
      saveCategory = null;
    }
    
    payload['category'] = saveCategory;

    dispatch(
      saveProductDetails({
        payload,
        history,
      }),
    );
  };

  const handleReject = () => {
    const payload = {
      status: 2,
      productId: +productId,
    };

    dispatch(
      updateProductReviewProduct({
        payload,
        history,
      }),
    );
  };

  const handleSearchNextProductBrand = () => {
    dispatch(getProductBrandsNext(BRAND_SEARCH_OFFSET.current, BRAND_SEARCH_LIMIT));
    BRAND_SEARCH_OFFSET.current += BRAND_SEARCH_LIMIT;
  }

  const handleSearchNextManufacturer = () => {
    dispatch(getProductManufacturesNext(MANUFACTURER_SEARCH_OFFSET.current, MANUFACTURER_SEARCH_LIMIT));
    MANUFACTURER_SEARCH_OFFSET.current += MANUFACTURER_SEARCH_LIMIT;
  }

  return (
    <div>
      <Header title="Product Details" />
      <div style={{ paddingTop: 60 }}>
        <Box mx={60} my={20}>
          <form>
            <Grid align="center">
              <Grid.Col span={LABEL_COL}>Name</Grid.Col>
              <Grid.Col span={FIELD_COL}>
                <TextInput placeholder="Product Name" {...getInputProps('name')} ref={nameInputRef} />
              </Grid.Col>
            </Grid>
            <Grid align="center">
              <Grid.Col span={LABEL_COL}>Barcode</Grid.Col>
              <Grid.Col span={FIELD_COL}>
                <TextInput placeholder="Barcode" {...getInputProps('barcode')} />
              </Grid.Col>
            </Grid>
            <Grid align="center">
              <Grid.Col span={LABEL_COL}>Brand</Grid.Col>
              <Grid.Col span={FIELD_COL}>
                <Select
                  isSearchable
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder={'Select a brand'}
                  value={values.brand}
                  options={[
                    ...brands,
                    { id: "more_items", name: "Loading more items...", isDisabled: true }
                  ]}
                  onChange={(value) => {
                    setValues({
                      brand: value,
                    });
                    BRAND_SEARCH_OFFSET.current = 20
                  }}
                  onInputChange={(value) => {
                    setBrandSearchValue(value);
                  }}
                  onMenuScrollToBottom={handleSearchNextProductBrand}
                />
                {_.has(errors, 'brand') && (
                  <Text className="p-3" c={'red'}>
                    {errors.brand}
                  </Text>
                )}
              </Grid.Col>
            </Grid>
            <Grid align="center">
              <Grid.Col span={LABEL_COL}>Submitting User</Grid.Col>
              <Grid.Col span={FIELD_COL}>
                <TextInput
                  placeholder="Submitting User"
                  {...getInputProps('submittingUser')}
                  ref={submittingUserRef}
                  disabled
                />
              </Grid.Col>
            </Grid>
            <Grid align="center">
              <Grid.Col span={LABEL_COL}>Category</Grid.Col>
              <Grid.Col span={FIELD_COL}>
                <Select
                  isClearable
                  isSearchable
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder={'Select a Category'}
                  options={categories}
                  value={_.find(categories, ['id', values.categoryRoot], null)}
                  onChange={(value) => {
                    selectCategoryBranchInputRef?.current?.clearValue();
                    setCategoriesBranch([]);
                    setCategoriesLeaf([]);
                    setCategoriesBranch(_.get(value, 'children', []));
                    setValues({
                      categoryRoot: _.get(value, 'id', ''),
                      categoryBranch: null,
                    });

                    if (_.isEmpty(value)) {
                      setValues({
                        categoryRoot: null,
                        categoryBranch: null,
                        categoryLeaf: null,
                      });
                    }
                  }}
                  ref={selectCategoryRootInputRef}
                />
                {_.has(errors, 'categoryRoot') && (
                  <Text className="p-3" c={'red'}>
                    {errors.categoryRoot}
                  </Text>
                )}
              </Grid.Col>
            </Grid>
            {categoriesBranch?.length > 0 && (
              <Grid align="center">
                <Grid.Col span={LABEL_COL}>Product Sub Category L1</Grid.Col>
                <Grid.Col span={FIELD_COL}>
                  <Select
                    isClearable
                    isSearchable
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={_.find(categoriesFlattened, ['id', values.categoryBranch], '')}
                    placeholder={'Select a Category'}
                    options={categoriesBranch}
                    onChange={(value) => {
                      setValues({
                        categoryBranch: _.get(value, 'id', ''),
                        categoryLeaf: null,
                      });

                      if (_.isEmpty(value)) {
                        setValues({
                          categoryLeaf: '',
                        });
                      }
                      setCategoriesLeaf(_.get(value, 'children', []));
                      selectCategoryLeafInputRef?.current?.clearValue();
                    }}
                    ref={selectCategoryBranchInputRef}
                  />
                  {_.has(errors, 'categoryBranch') && (
                    <Text className="p-3" c={'red'}>
                      {errors.categoryBranch}
                    </Text>
                  )}
                </Grid.Col>
              </Grid>
            )}
            {categoriesBranch.length > 0 && categoriesLeaf.length > 0 && (
              <Grid align="center">
                <Grid.Col span={LABEL_COL}>Product Sub Category L2</Grid.Col>
                <Grid.Col span={FIELD_COL}>
                  <Select
                    isClearable
                    isSearchable
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder={'Select a Category'}
                    value={_.find(categoriesFlattened, ['id', values.categoryLeaf], null)}
                    options={categoriesLeaf}
                    onChange={(value) => {
                      setValues({
                        categoryLeaf: _.get(value, 'id', ''),
                      });
                    }}
                    ref={selectCategoryLeafInputRef}
                  />
                  {_.has(errors, 'categoryLeaf') && (
                    <Text className="p-3" c={'red'}>
                      {errors.categoryLeaf}
                    </Text>
                  )}
                </Grid.Col>
              </Grid>
            )}
            <Grid align="center">
              <Grid.Col span={LABEL_COL}>Manufacturer</Grid.Col>
              <Grid.Col span={FIELD_COL}>
                <Select
                  isSearchable
                  isClearable
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder={'Select a manufacturer'}
                  options={[
                    ...manufacturer,
                    { id: "more_items", name: "Loading more items...", isDisabled: true }
                  ]}
                  value={values.manufacturer}
                  onChange={(value) => {
                    setValues({
                      manufacturer: value,
                    });
                    MANUFACTURER_SEARCH_OFFSET = 20;
                  }}
                  onInputChange={(value) => {
                    setManufacturerSearchValue(value);
                  }}
                  onMenuScrollToBottom={handleSearchNextManufacturer}
                />
                {_.has(errors, 'manufacturer') && (
                  <Text className="p-3" c={'red'}>
                    {errors.manufacturer}
                  </Text>
                )}
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={LABEL_COL} ref={ingredientRef}>Ingredient</Grid.Col>
              <Grid.Col span={FIELD_COL}>
                <Grid gutter={'xl'}>
                  <Grid.Col span={6}>
                    <div>
                      <Card shadow="sm" padding="xl" radius={'md'}>
                        <Card.Section
                          sx={{
                            alignItems: 'center',
                            textAlign: 'center',
                            paddingTop: 10,
                            paddingBottom: 10,
                            backgroundColor: '#00c9b7',
                            color: '#414b4f',
                          }}
                        >
                          Available Ingredients
                        </Card.Section>
                        <Input
                          placeholder='Search'
                          value={ingredientSearchValue}
                          onChange={(event) => {
                            setIngredientSearchValue(event.currentTarget.value);
                          }}
                          className="py-2"
                        />
                        <Card.Section>
                          <Divider />
                        </Card.Section>
                        <Card.Section>
                          <ScrollArea h={200} className="px-2">
                            {filteredIngredients?.map((item, index) => {
                              return (
                                <Text
                                  key={index}
                                  onClick={() => {
                                    selectIngredientViewPort?.current?.scrollTo({
                                      top: selectIngredientViewPort?.current?.scrollHeight,
                                      behavior: 'smooth',
                                    });
                                    setValues({
                                      ingredient: [...values.ingredient, item],
                                    });
                                  }}
                                  sx={{
                                    marginTop: 5,
                                    padding: 2,
                                    ':hover': {
                                      cursor: 'pointer',
                                      backgroundColor: '#00c9b7',
                                      color: '#fff',
                                      borderRadius: 5,
                                    },
                                  }}
                                >
                                  {item.name}
                                </Text>
                              );
                            })}
                          </ScrollArea>
                        </Card.Section>
                      </Card>
                    </div>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <div>
                      <Card shadow="sm" padding="xl" radius={'md'}>
                        <Card.Section
                          sx={{
                            alignItems: 'center',
                            textAlign: 'center',
                            paddingTop: 10,
                            paddingBottom: 10,
                            backgroundColor: '#414b4f',
                            color: '#ffffff',
                          }}
                        >
                          Selected Ingredients
                        </Card.Section>
                        <Card.Section>
                          <ScrollArea h={254} className="px-2" viewportRef={selectIngredientViewPort}>
                            {values?.ingredient?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Text sx={{ marginTop: 5 }}>{item.name}</Text>
                                  <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    className="p-2"
                                    style={{ color: '#00c9b7', cursor: 'pointer' }}
                                    onClick={() => {
                                      setValues({
                                        ingredient: _.remove(
                                          values.ingredient,
                                          (valueItem) => valueItem.id !== item.id,
                                        ),
                                      });
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </ScrollArea>
                        </Card.Section>
                      </Card>
                    </div>
                  </Grid.Col>
                </Grid>
                {_.has(errors, 'ingredient') && (
                  <Text className="p-3" c={'red'}>
                    {errors.ingredient}
                  </Text>
                )}
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={LABEL_COL}>Comments</Grid.Col>
              <Grid.Col span={FIELD_COL}>
                <Textarea placeholder="Comments" {...getInputProps('description')} minRows={5} />
              </Grid.Col>
            </Grid>
            <Grid align="center">
              <Grid.Col span={LABEL_COL}>Created at</Grid.Col>
              <Grid.Col span={FIELD_COL}>
                <Text>{dayjs(values.createdAt).format(DAYJS_FORMAT)}</Text>
              </Grid.Col>
            </Grid>
            <Grid align="center">
              <Grid.Col span={LABEL_COL}>Last Updated</Grid.Col>
              <Grid.Col span={FIELD_COL}>
                <Text>{dayjs(values.updatedAt).format(DAYJS_FORMAT)}</Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={LABEL_COL}>Product Images</Grid.Col>
              <Grid.Col span={FIELD_COL}>
                {productImageFields.map((item, index) => {
                  return (
                    <div key={index} className="my-2">
                      <ProductReviewImage
                        formValues={values}
                        formFieldName={item.formFieldName}
                        formSetValue={setValues}
                        imageIdentifier={item.imageIdentifier}
                        position={item.position}
                        required={item.required}
                      />
                      {_.has(errors, item.formFieldName) && (
                        <Text className="p-3" c={'red'}>
                          {errors[item.formFieldName]}
                        </Text>
                      )}
                    </div>
                  );
                })}
                <div>
                  <AdditionalImageReviewComponent
                    currentImage={values.additionalProductImage1}
                    formFieldName={'additionalProductImage1'}
                    formSetValue={setValues}
                    imageIdentifier={'Additional Image 1'}
                    position={3}
                  />
                </div>

                {(!_.isEmpty(values.additionalProductImage1.image) ||
                  !!values.additionalProductImage1?.newImage?.size) && (
                  <AdditionalImageReviewComponent
                    currentImage={values.additionalProductImage2}
                    formFieldName={'additionalProductImage2'}
                    formSetValue={setValues}
                    imageIdentifier={'Additional Image 2'}
                    position={4}
                  />
                )}
              </Grid.Col>
            </Grid>
          </form>
          <Grid>
            <Grid.Col span={LABEL_COL + FIELD_COL}>
              <div className="d-flex flex-row-reverse mx-5 mb-5">
                {(values.status === 1 || values.status === 0) && (
                  <Button
                    className="mx-2"
                    onClick={() => {
                      handleReject();
                    }}
                    color="red"
                    loading={updatingCurrentProductDetails || updatingProductStatus}
                  >
                    Reject
                  </Button>
                )}
                <Button
                  className="mx-2"
                  onClick={() => {
                    handleFormValidation();
                  }}
                  loading={updatingCurrentProductDetails || updatingProductStatus}
                  sx={{
                    backgroundColor: '#00c9b7',
                    color: '#333333',
                    ':hover': {
                      backgroundColor: '#00c9b7',
                      color: '#fff',
                    },
                  }}
                >
                    Publish
                </Button>
                {values.status === 1 && (
                  <Button
                    className="mx-2"
                    onClick={() => {
                      handleSave();
                    }}
                    loading={updatingCurrentProductDetails || updatingProductStatus}
                    sx={{
                      backgroundColor: '#00c9b7',
                      color: '#333333',
                      ':hover': {
                        backgroundColor: '#00c9b7',
                        color: '#fff',
                      },
                    }}
                  >
                    Save
                  </Button>
                )}
                <Button
                  className="mx-2"
                  variant="white"
                  onClick={() => {
                    history.push('/products-reviews');
                  }}
                  sx={{
                    backgroundColor: '#f5fbff',
                    color: '#414b4f',
                    ':hover': {
                      backgroundColor: '#f5fbff',
                      color: '#414b4f',
                    },
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid.Col>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
