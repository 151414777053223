// Categories

export const GET_PRODUCT_CATEGORIES = 'SAFELIST/GET_PRODUCT_CATEGORIES';
export const GET_PRODUCT_CATEGORIES_SUCCESS =
  'SAFELIST/GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_CATEGORIES_ERRORS =
  'SAFELIST/GET_PRODUCT_CATEGORIES_ERRORS';
export const SET_ACTIVE_TAB = 'SAFELIST/SET_ACTIVE_TAB';
export const SET_VIEW_BY_TYPE = 'SAFELIST/SET_VIEW_BY_TYPE';
// Review product
export const POST_REVIEW_PRODUCT = 'SAFELIST/POST_REVIEW_PRODUCT';
export const POST_REVIEW_PRODUCT_SUCCESS =
  'SAFELIST/POST_REVIEW_PRODUCT_SUCCESS';
export const POST_REVIEW_PRODUCT_ERRORS = 'SAFELIST/POST_REVIEW_PRODUCT_ERRORS';
// Report product
export const POST_REPORT_PRODUCT = 'SAFELIST/POST_REPORT_PRODUCT';
export const POST_REPORT_PRODUCT_SUCCESS =
  'SAFELIST/POST_REPORT_PRODUCT_SUCCESS';
export const POST_REPORT_PRODUCT_ERRORS = 'SAFELIST/POST_REPORT_PRODUCT_ERRORS';
// Submit a product
export const POST_SUBMIT_PRODUCT = 'SAFELIST/POST_SUBMIT_PRODUCT';
export const POST_SUBMIT_PRODUCT_SUCCESS =
  'SAFELIST/POST_SUBMIT_PRODUCT_SUCCESS';
export const POST_SUBMIT_PRODUCT_ERRORS = 'SAFELIST/POST_SUBMIT_PRODUCT_ERRORS';
export const UPLOAD_PRODUCT_PHOTO = 'SAFELIST/UPLOAD_PRODUCT_PHOTO';
export const UPLOAD_PRODUCT_PHOTO_SUCCESS =
  'SAFELIST/UPLOAD_PRODUCT_PHOTO_SUCCESS';
export const UPLOAD_PRODUCT_PHOTO_ERRORS =
  'SAFELIST/UPLOAD_PRODUCT_PHOTO_ERRORS';
// get a product unsafe ingredients
export const GET_UNSAFE_INGREDIENTS = 'SAFELIST/GET_UNSAFE_INGREDIENTS';
export const SET_UNSAFE_INGREDIENTS = 'SAFELIST/SET_UNSAFE_INGREDIENTS';
export const SET_UNSAFE_INGREDIENTS_LOADING = 'SAFELIST/SET_UNSAFE_INGREDIENTS_LOADING';
export const EMPTY_UNSAFE_INGREDIENTS = 'SAFELIST/EMPTY_UNSAFE_INGREDIENTS';
// get a product brands
export const GET_PRODUCT_BRANDS = 'SAFELIST/GET_PRODUCT_BRANDS';
export const GET_PRODUCT_BRANDS_SUCCESS = 'SAFELIST/GET_PRODUCT_BRANDS_SUCCESS';
export const GET_PRODUCT_BRANDS_ERROR = 'SAFELIST/GET_PRODUCT_BRANDS_ERROR';
export const GET_PRODUCT_BRANDS_NEXT = 'SAFELIST/GET_PRODUCT_BRANDS_NEXT';
// get a product manufacturer
export const GET_PRODUCT_MANUFACTURERS = 'SAFELIST/GET_PRODUCT_MANUFACTURERS';
export const GET_PRODUCT_MANUFACTURERS_SUCCESS =
  'SAFELIST/GET_PRODUCT_MANUFACTURERS_SUCCESS';
export const GET_PRODUCT_MANUFACTURERS_ERROR =
  'SAFELIST/GET_PRODUCT_MANUFACTURERS_ERROR';
export const GET_PRODUCT_MANUFACTURERS_NEXT =
  'SAFELIST/GET_PRODUCT_MANUFACTURERS_NEXT';
// add a product
export const SET_ADD_PRODUCT_SUBMITTING = 'SAFELIST/SET_ADD_PRODUCT_SUBMITTING';
export const RESET_PRODUCT_STATE = 'SAFELIST/RESET_PRODUCT';

export const setActiveTab = (category) => {
  return {
    type: SET_ACTIVE_TAB,
    category,
  };
};

export const setViewBy = (viewBy) => {
  return {
    type: SET_VIEW_BY_TYPE,
    viewBy,
  };
};

export const safelistReviewProduct = (params) => {
  return {
    type: POST_REVIEW_PRODUCT,
    params,
  };
};

export const safelistReportProduct = (params, history) => {
  return {
    type: POST_REPORT_PRODUCT,
    params,
    history,
  };
};

export const safelistGetProductCategories = () => {
  return {
    type: GET_PRODUCT_CATEGORIES,
  };
};

export const safelistSubmitProduct = (params) => {
  return {
    type: POST_SUBMIT_PRODUCT,
    params,
  };
};

export const safelistUploadPhotos = (photo, productId, position) => {
  return {
    type: UPLOAD_PRODUCT_PHOTO,
    photo,
    productId,
    position,
  };
};

export const getProductCategories = () => {
  return {
    type: GET_PRODUCT_CATEGORIES,
  };
};

export const getUnsafeIngredients = ({ code1, code2 }) => {
  return {
    type: GET_UNSAFE_INGREDIENTS,
    code1,
    code2,
  };
};

export const setUnsafeIngredients = (payload) => {
  return {
    type: SET_UNSAFE_INGREDIENTS,
    payload,
  };
};

export const emptyUnsafeIngredients = (payload) => {
  return {
    type: EMPTY_UNSAFE_INGREDIENTS,
    payload,
  };
};

export const getProductBrands = (params) => {
  return {
    type: GET_PRODUCT_BRANDS,
    params,
  };
};

export const getProductBrandsNext = (offset, limit) => {
  return {
    type: GET_PRODUCT_BRANDS_NEXT,
    offset,
    limit
  };
};

export const getProductManufactures = (params) => {
  return {
    type: GET_PRODUCT_MANUFACTURERS,
    params,
  };
};

export const getProductManufacturesNext = (offset, limit) => {
  return {
    type: GET_PRODUCT_MANUFACTURERS_NEXT,
    offset,
    limit
  };
};

export const setProductSubmitting = (value) => {
  return {
    type: SET_ADD_PRODUCT_SUBMITTING,
    value,
  };
};

export const setUnsafeIngredientsLoading = (isLoading) => {
  return {
    type: SET_UNSAFE_INGREDIENTS_LOADING,
    payload: isLoading,
  };
};
